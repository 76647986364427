<mat-toolbar id="toolbar" class="fixed-top"
  [ngClass]="{'px-4 pt-4 overflow-hidden': windowWidth < 600, 'reteclima': reteclima}" #toolbar
  [color]="((router.url === '/article') || scrollY > 0) ? 'dark': ''">
  <div class="container">
    <nav class="navbar navbar-expand-xxl">
      <div class="container-fluid p-0">
        <div class="d-flex justify-content-between w-100 d-xxl-none">
          <div class="logo me-0">
            <a class="navbar-brand me-0" routerLink="/">
              <img src="/assets/images/svg/logo_full.svg">
            </a>
          </div>
          <div class="d-flex justify-content-end gap-2" *ngIf="!aldar">
            <div class="align-items-center configbar" [ngClass]="{'reteclima': reteclima}" (click)="openLocaleModal()">
              <img src="assets/images/{{lang}}.svg" class="flag px-2" />
            </div>
            <div class="d-block d-md-none d-lg-none">
              <button mat-stroked-button color="yellow" class="p-0 w-40" [routerLink]="['admin']" *ngIf="authenticated && !opened">
                <img width="24" src="../../../assets/images/icons/home.svg" />
              </button>
            </div>
            <div class="d-block d-md-none d-lg-none access dropdown">
              <button mat-icon-button color="lime" (click)="openExpertModal()" *ngIf="!opened">
                <img width="24" src="../../../assets/images/icons/headset.svg" />
              </button>
              <mat-menu #menu="matMenu" class="mt-2 ms-3">
                <a mat-menu-item class="chivo" *ngIf="lang!='jp'" href="tel:+393756578850">
                  <svg-icon src="../../../assets/images/icons/phone.svg"></svg-icon>
                  +39 375 657 8850
                </a>
                <button mat-menu-item class="chivo" (click)="createBookADemoLink()">
                  <svg-icon src="../../../assets/images/icons/calendar.svg"></svg-icon>
                  {{'landingPublic.menu.bookADemo' | translate}}
                </button>
              </mat-menu>
            </div>
            <div class="d-none d-md-block access" *ngIf="(username || authenticated)">
              <button mat-stroked-button class="login" color="yellow" (click)="admin()">
                {{username}}
              </button>
            </div>
            <div class="d-none d-md-block access" *ngIf="!(username || authenticated)">
              <button mat-stroked-button color="yellow" [routerLink]="['admin']">
                <svg-icon src="../../../assets/images/icons/thunder.svg"></svg-icon>
                {{'landingPublic.menu.access'|translate}}
              </button>
            </div>
            <div class="d-none d-md-block access dropdown">
              <button mat-raised-button color="lime" [matMenuTriggerFor]="menu">
                <svg-icon src="../../../assets/images/icons/headset.svg"></svg-icon>
                {{'landingPublic.menu.talkToExpert'|translate}}
              </button>
              <mat-menu #menu="matMenu" class="mt-2">
                <a mat-menu-item class="chivo" *ngIf="lang!='jp'" href="tel:+393756578850">
                  <svg-icon src="../../../assets/images/icons/phone.svg"></svg-icon>
                  +39 375 657 8850
                </a>
                <button mat-menu-item class="chivo" (click)="createBookADemoLink()">
                  <svg-icon src="../../../assets/images/icons/calendar.svg"></svg-icon>
                  {{'landingPublic.menu.bookADemo' | translate}}
                </button>
              </mat-menu>
            </div>
            <button mat-icon-button color="transparent" (click)="toggleSidenav.emit();isOpened()">
              <mat-icon *ngIf="!opened">menu</mat-icon>
              <img style="width: 20px" *ngIf="opened" src="../../../assets/images/close.svg" alt="">
            </button>
          </div>
          <div class="d-flex justify-content-end gap-3" *ngIf="aldar">
            <a _ngcontent-ng-c452859406="" href="https://www.aldar.com/" target="_blank"><img
                _ngcontent-ng-c452859406="" src="/assets/images/icons/aldar_logo_white.svg" alt="ALDAR"
                class="sidebar-logo aldar"></a>
            <div *ngIf="windowWidth > 768 && windowWidth < 1400">
              <ng-container *ngIf="username || authenticated, else noLog">
                <div class="d-flex justify-content-between gap-3">
                  <div class="d-none d-md-block access">
                    <button mat-stroked-button class="login" color="yellow" (click)="admin()">
                      {{username}}
                    </button>
                  </div>
                  <div class="d-none d-md-block access dropdown">
                    <button mat-raised-button color="lime" [matMenuTriggerFor]="menu">
                      <svg-icon src="../../../assets/images/icons/headset.svg"></svg-icon>
                      {{'landingPublic.menu.talkToExpert'|translate}}
                    </button>
                    <mat-menu #menu="matMenu" class="mt-2">
                      <a mat-menu-item class="chivo" *ngIf="lang!='jp'" href="tel:+393756578850">
                        <svg-icon src="../../../assets/images/icons/phone.svg"></svg-icon>
                        +39 375 657 8850
                      </a>
                      <button mat-menu-item class="chivo" (click)="createBookADemoLink()">
                        <svg-icon src="../../../assets/images/icons/calendar.svg"></svg-icon>
                        {{'landingPublic.menu.bookADemo' | translate}}
                      </button>
                    </mat-menu>
                  </div>
                </div>

              </ng-container>
              <ng-template #noLog>
                <div class="d-flex justify-content-between gap-3">
                  <div class="d-none d-xxl-block access">
                    <button mat-stroked-button color="yellow" [routerLink]="['admin']">
                      <svg-icon src="../../../assets/images/icons/thunder.svg"></svg-icon>
                      {{'landingPublic.menu.access'|translate}}
                    </button>
                  </div>
                  <div class="d-none d-xxl-block access dropdown">
                    <button mat-raised-button color="lime" [matMenuTriggerFor]="menu">
                      <svg-icon src="../../../assets/images/icons/headset.svg"></svg-icon>
                      {{'landingPublic.menu.talkToExpert'|translate}}
                    </button>
                    <mat-menu #menu="matMenu" class="mt-2">
                      <a mat-menu-item class="chivo" *ngIf="lang!='jp'" href="tel:+393756578850">
                        <svg-icon src="../../../assets/images/icons/phone.svg"></svg-icon>
                        +39 375 657 8850
                      </a>
                      <button mat-menu-item class="chivo" (click)="createBookADemoLink()">
                        <svg-icon src="../../../assets/images/icons/calendar.svg"></svg-icon>
                        {{'landingPublic.menu.bookADemo' | translate}}
                      </button>
                    </mat-menu>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="logo d-none d-xxl-block me-0">
          <a class="navbar-brand me-0" routerLink="/">
            <!-- <img class="logoDesktop" [src]="reteclima ? '/assets/images/icons/logo_small_green.svg' : '/assets/images/svg/logo.svg'" alt="GFP"> -->
            <img class="logoDesktop" [src]="'/assets/images/svg/logo.svg'" alt="GFP">
          </a>
        </div>
        <div class="collapse navbar-collapse ms-3">
          <ul class="navbar-nav">
            <li class="nav-item" [class.dropdown]="item.children" [class.active]="item.active"
              *ngFor="let item of menuItemsApp">
              <ng-container *ngIf="item.children && item.visible !== false">
                <a class="title-4 nav-link mat-subheading-1 nav-item-title"
                  [ngClass]="item.children ? 'dropdown-toggle' : ''" [routerLink]="!item.children?item.route:undefined"
                  role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {{'landingPublic.menu.' + item.displayName | translate}}
                </a>
                <ul class="dropdown-menu" *ngIf="item.children">
                  <ng-container *ngFor="let drop of item.children">
                    <li *ngIf="drop.visible != false && !drop.store && (!drop.type || drop.type == clientType)">
                      <a class="dropdown-item bg-white" *ngIf="drop.displayName!=='blog'else externalLink"
                        [class.active]="drop.active"
                        [routerLink]="lang == 'it' && drop.route_it ? item.route + drop.route_it : lang == 'jp' && drop.route_jp ? item.route + drop.route_jp : item.route + drop.route"
                        (click)="item.store ? store(item.store) : null"
                        [target]="drop.displayName == 'blog'? '_blank':''">
                        <div class="d-flex align-items-center w-100 pt-1 pb-1">
                          <img [src]="'/assets/images/public/menu/'+drop.image+'.svg'" class="me-3" *ngIf="drop.image">
                          <span class="text-black nav-item-item"
                            [innerHTML]="'landingPublic.menu.children.' + drop.displayName | translate"></span>
                        </div>
                      </a>
                      <ng-template #externalLink>
                        <a class="dropdown-item bg-white" [class.active]="drop.active"
                          [href]="lang == 'it' && drop.route_it ? item.route + drop.route_it : lang == 'jp' && drop.route_jp ? item.route + drop.route_jp : item.route + drop.route"
                          (click)="item.store ? store(item.store) : null"
                          [target]="drop.displayName == 'blog'? '_blank':''">
                          <div class="d-flex align-items-center w-100 pt-1 pb-1">
                            <img [src]="'/assets/images/public/menu/'+drop.image+'.svg'" class="me-3"
                              *ngIf="drop.image">
                            <span class="text-black nav-item-item"
                              [innerHTML]="'landingPublic.menu.children.' + drop.displayName | translate"></span>
                          </div>
                        </a>
                      </ng-template>
                    </li>
                    <li *ngIf="drop.visible != false && drop.store && (!drop.type || drop.type == clientType)">
                      <a class="dropdown-item text-black nav-item-item" (click)="store(drop.store)">
                        {{'landingPublic.menu.children.' + drop.displayName | translate}}
                      </a>
                    </li>
                  </ng-container>
                </ul>
              </ng-container>
              <ng-container *ngIf="!item.children && item.visible !== false">
                <ng-container *ngIf="item.displayName !== 'logout'">
                  <a class="title-4 nav-link mat-subheading-1 nav-item-title" [routerLink]="item.route" role="button"
                    aria-expanded="false">{{'landingPublic.menu.' + item.displayName | translate}}</a>
                </ng-container>
              </ng-container>
            </li>
          </ul>
        </div>
        <div class="d-flex justify-content-between gap-3">
          <div class="configbar d-none d-xxl-flex" [ngClass]="{'reteclima': reteclima}" (click)="openLocaleModal()">
            <div class="d-flex align-items-center">
              <img src="assets/images/{{lang}}.svg" class="flag" />
            </div>
          </div>
          <div class="d-none d-xxl-block" *ngIf="(username || authenticated)">
            <button mat-stroked-button class="login" color='lime' (click)="admin()">
              {{username}}
            </button>
          </div>
          <div class="d-none d-xxl-block access" *ngIf="!(username || authenticated)">
            <button mat-stroked-button color="yellow" [routerLink]="['admin']">
              <svg-icon src="../../../assets/images/icons/thunder.svg"></svg-icon>
              {{'landingPublic.menu.access'|translate}}
            </button>
          </div>
          <div class="d-none d-xxl-block access dropdown">
            <button mat-raised-button color="lime" [matMenuTriggerFor]="menu">
              <svg-icon src="../../../assets/images/icons/headset.svg"></svg-icon>
              {{'landingPublic.menu.talkToExpert'|translate}}
            </button>
            <mat-menu #menu="matMenu" class="mt-2">
              <a mat-menu-item class="chivo" *ngIf="lang!='jp'" href="tel:+393756578850">
                <svg-icon src="../../../assets/images/icons/phone.svg"></svg-icon>
                +39 375 657 8850
              </a>
              <button mat-menu-item class="chivo" (click)="createBookADemoLink()">
                <svg-icon src="../../../assets/images/icons/calendar.svg"></svg-icon>
                {{'landingPublic.menu.bookADemo' | translate}}
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </nav>
  </div>
</mat-toolbar>