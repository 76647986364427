import {Component, EventEmitter, HostBinding, Input, OnInit, Output, Inject, HostListener } from '@angular/core';
import {Router} from '@angular/router';
import { DOCUMENT } from '@angular/common';
import {NavItem} from '../../_models/components/nav-item';
import {BaseComponent} from '../../pages/_base/base/base.component';
import {LoggedUserService} from '../../_services/public/local/logged-user.service';
import {AuthService} from '../../_services/_base/auth.service';
import { PilioModalComponent } from '../pilio-modal/pilio-modal.component';
import { StorageName } from 'src/app/_models/components/storage-name';
import {AuthenticationService} from '../../_services/authentication/authentication.service';
import {Response} from '../../_models/api/response';
import { environment } from 'src/environments/environment';
import { RoutingTypes } from 'src/app/_models/components/routing-types';
import { SubscriptionTypes } from 'src/app/_models/components/subscription-types';
import { PartnershipNames } from 'src/app/_models/components/partnership-names';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent extends BaseComponent implements OnInit {
  expanded = false;
  rippleState: boolean;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: NavItem;
  @Input() menuItemsGen: NavItem[];
  @Input() route = '';
  @Input() depth: number;
  @Output() toggleItemSidenav = new EventEmitter<void>();
  username: string;
  authenticated: boolean = false;
  pilio: boolean = false;
  academyUserCode: string = localStorage.getItem(StorageName.academy);
  firstPilioAccess: boolean = true;
  pilioLink: string = '';
  openWrong: boolean = false;
  windowWidth:any = window.innerWidth
  lang: string = localStorage.getItem('lang') || "en";
  routingTypes = RoutingTypes;

  reteclima = localStorage.getItem(StorageName.partnership) === PartnershipNames.reteclima;
  adnoc = localStorage.getItem(StorageName.partnership) === PartnershipNames.adnoc;
  aldar = localStorage.getItem(StorageName.partnership) === PartnershipNames.aldar;
  tgi = localStorage.getItem(StorageName.partnership) === PartnershipNames.tgi;
  whitelabel = localStorage.getItem(StorageName.partnership) === PartnershipNames.whitelabel;

  constructor(
    public router: Router,
    private auth: AuthService,
    private authenticationService: AuthenticationService,
    @Inject(DOCUMENT) private document: Document
    // private loggedUserService: LoggedUserService,
) {
    super();
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit(): void {
    this.getUsername();
    this.assignState();
    this.getPilio().then();
  }

  getPilio = async() => {
    if (this.item.displayName === 'energy') {
      const pilio = localStorage.getItem(StorageName.pilio);
      if (pilio) {
        if (Number(pilio)) {
          this.pilio = true;
        }
      }
      const pilioLastAccess = localStorage.getItem(StorageName.pilioLastAccess);
      if (pilioLastAccess && pilioLastAccess !== 'undefined') {
        this.firstPilioAccess = false;
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowWidth = event.target.innerWidth;
  }

  assignState(): boolean{
    this.rippleState = this.item.enable === false;
    return this.rippleState;
  }

  async onItemSelected(item: NavItem): Promise<void> {
    if (this.item.displayName === 'energy' && this.item.route !== '/carbon_footprint_management') {
      await this.openPilioDialog();
    } else if (this.item.displayName.startsWith('sustainability') && this.item.route === '/admin/sustainability/contribution') {
      await this.openSustainabilitySettings();
    } else if (this.item.displayName === 'whitelabel' && this.item.route === '/admin/whitelabel/general') {
      await this.openWhitelabelSettings();
    } else if (this.item.displayName === 'academy' && this.item.route === '/admin/academy') {
      await this.openAcademy();
    } else {
      if (!item.children || !item.children.length) {
        if (window.innerWidth < 991) {
          this.toggleItemSidenav.emit();
        }

        if(item.displayName == 'blog'){
            this.lang == 'it' ? window.open(item.route_it) : window.open(item.route)
            // location.reload()
        } else {
          let route = '/';
          if (item.route) {
            if(this.lang == 'it' && item.route_it){
             route = this.route + item.route_it
            } else{
             route = this.route + item.route
            }
            // setTimeout(() => {
            //     // location.reload()
            // }, 500);
          }
          if (item.store) {
            localStorage.setItem('clientType', item.store.toString());
          }
          if (this.router.url == '/' && route == '/') {
            // location.reload();
          } else {
            await this.router.navigate([route]);
            // if (route == '/') {
            //   setTimeout(() => {
            //     location.reload();
            //   }, 500)
            // }
          }
        }
      }
      if (item.children && item.children.length) {
        this.menuItemsGen.map((item)=>{
          if(item.displayName !== this.item.displayName){
            item.expanded = false
          }
        });
        this.item.expanded = !this.item.expanded;
        // window.dispatchEvent(new Event('resize'));
      }
    }
  }

  getUsername(): void {
    this.loggedUserService.logged.subscribe(() => {
      this.username = this.loggedUserService.getUsername();
    })
    this.username = this.loggedUserService.getUsername();
    if (this.auth.isAuthenticated()) {
      this.authenticated = true;
      if (!this.username) {
        this.username = this.translate.instant('landingPublic.menu.access');
      }
    }
  }

  close(event, name): void {
    event.stopPropagation();
    const wrongInfo = document.getElementById('menu-wrong-' + name);
    wrongInfo.classList.add('d-none');
  }

  openSustainabilitySettings = async () => {
    const subscription = localStorage.getItem(StorageName.subscription);
    if (subscription === SubscriptionTypes.climatePioneer) {
      this.router.navigate(['admin', 'sustainability', 'contribution']);
    } else {
      const wrongInfo = this.document.getElementById('menu-wrong-sustainability');
      wrongInfo.classList.remove('d-none');
    }
  }

  openWhitelabelSettings = async () => {
    const enabled = localStorage.getItem(StorageName.partnership) == PartnershipNames.whitelabel;
    if (enabled) {
      this.router.navigate(['admin', 'whitelabel', 'general']);
    } else {
      const wrongInfo = this.document.getElementById('menu-wrong-whitelabel');
      wrongInfo.classList.remove('d-none');
    }
  }

  openPilioDialog = async () => {
    if (this.pilio) {
      if (this.firstPilioAccess) {
        this.dialog.open(PilioModalComponent);
      } else {
        // navigate to Pilio
        try {
          const userData = localStorage.getItem('userData');
          if (userData) {
            const email = JSON.parse(userData).email;
            if (email) {
              const response: Response<any> = await this.authenticationService.loginPilio(email);
              if (response?.success) {
                const token = response.data.token;
                if (token) {
                  window.open(environment.pilioLoginUrl + '?magic_token=' + token);
                }
              } else {
                this.showErrorResponse(response);
              }
            }
          }
        } catch (e) {
          this.showErrorResponse(e);
        }
      }
    } else {
      const wrongInfo = this.document.getElementById('menu-wrong-energy');
      wrongInfo.classList.remove('d-none');
    }
  }

  openAcademy = async () => {
    if (this.academyUserCode){
      window.open(environment.augmentalLoginUrl + '?authentication=' + this.academyUserCode);
    }
    else{
      const wrongInfo = this.document.getElementById('menu-wrong-academy');
      wrongInfo.classList.remove('d-none');
    }
  }
}
